import React, { useState } from "react";
import overallservices from "../services/overallservices";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Video = ({video}) => {
  const [isLoading, setLoading] = useState(false)
  const [link, setLink] = useState('');
  const { user } = useSelector((state) => state.auth);
  const token=user.token
  useEffect(() => {
    // setLoading(true)
    // overallservices
    // .videocontent(video,token)
    // .then((res) => {
    //     setLoading(false)
    //     setLink(res.data)
    //     console.log(link,"is here")
    //   })
    //   .catch((err) => {
    //     setLoading(false)
    //     console.log("Error while listing the data");
    //     console.log(link,"not")
    //   });
  }, [video,token]);

  return (
    <>
    {
      isLoading &&   <div style={{display:"flex",justifyContent:"center"}}>
     
    </div>
    }
    {
      !isLoading &&  <video
      autoPlay
      style={{ width: "600px" }}
      src={video.startsWith("https://") ? video : `https://iparhai-videos.blr1.digitaloceanspaces.com/${video}`}
      controls
    >
    </video>
    }
    </>
  );
};

export default Video;
