import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, TextField, Button, IconButton, Fade, Modal, Backdrop, Typography, Box, InputBase } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from "react-redux";
import { login } from "../actions/auth";
import Ilogo from "../assests/ifinal.svg";
import Loading from "../components/loading"
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import authService from '../services/auth.service';
import ReCAPTCHA from 'react-google-recaptcha';

const customTheme = createTheme({
  palette: {
    // Replace 'blue' with your desired outline color
  },
});

const Login = () => {
  const textFieldStyle = {
    fontFamily: "'Poppins', Arial, sans-serif",
    backgroundColor: '#fbf1ea',
    outlineColor: '#fbf1ea',
  };

  const buttonlogin = {
    width: "80%",
    marginTop: "2%",
    marginRight: "15%",
    borderRadius: "8px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
  };

  const buttonlogin1 = {
    width: "80%",
    marginTop: "8%",
    marginRight: "15%",
    borderRadius: "8px",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
  };

  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState('')
  const [password, setPassword] = useState("");
  const [recaptcha, setRecaptcha] = useState('')
  const [verifyloading, setverifyLoading] = useState(false);
  const [forgetPasswordModal, setForgetPasswordModal] = useState(false)
  const [emailSendLoading, setEmailSendLoading] = useState(false)
  const [verifyotploading, setverifyotpLoading] = useState(false);
  const [loadingResendOTP, setLoadingResendOTP] = useState(false);
  const [forgetEmail, setForgetEmail] = useState('')
  const [remainingTime, setRemainingTime] = useState(60);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpmodal, setotpModal] = useState(false);
  const { message } = useSelector((state) => state.message);
  const [otperror, setotpError] = useState({
    isError: false,
    errorMessage: "",
  });
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if(!recaptcha){
      alert('Please verify captcha')
      setLoading(false)
      return
    }
    dispatch(login(email, password,recaptcha))
      .then((res) => {
        console.log(res)
        if (res.status === 403) {
          setotpModal(true)
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (message === 'loging Success') {
      alert(message);
      window.location.reload();
    }
    if (message === "Invalid Password!" || message === "User Not found") {
      alert(message);
    }
  }, [ loading]);

  useEffect(() => {
   
    setRemainingTime(60);
    startTimer();
  }, [otpmodal]);


  const handleVerifyOTP = async () => {
    try {
      const res = await axios.post('/api/auth/verifyOTP', { email, OTP })
      console.log(res)
      setotpModal(!otpmodal)
      localStorage.setItem("user", JSON.stringify(res.data))
      window.location.reload();

    } catch (error) {
      console.log(error.response)
      setotpModal(!otpmodal)
    }

  }

  const startTimer = () => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime === 0) {

          clearInterval(timer);
          return 0;
        }
        if (!otpmodal) {
          clearInterval(timer);

          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return;
  };

  const handleResendOtp = async () => {
    try {
      setLoadingResendOTP(true);
      const res = await authService.resendOTP(email);
      console.log(res);
      setRemainingTime(60)
      startTimer()
      // if (res.status === 403) {
      //   setBlockModal(true);
      //   setLoadingResendOTP(false);
      //   // setRemainingTime(10);
      //   setTimeout(() => {
      //     setBlockModal(false);
      //   }, 3000);
      //   return;
      // }
      setLoadingResendOTP(false);
    } catch (error) {
      setLoadingResendOTP(false);
      console.log(error);
    }
  };

  const sendFPLink = async () => {
    try {
      setEmailSendLoading(true)
      const res = await axios.post('/api/auth/sendForgetPasswordLink', { forgetEmail })
      alert('Email Successfully Sent.')
      setEmailSendLoading(false)
      setForgetEmail('')
      setForgetPasswordModal(!forgetPasswordModal)
      console.log(res)

    } catch (error) {
      setEmailSendLoading(false)
      alert('Try again.')
      console.log(error)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        {loading ? (
          <Loading />
        ) : (
          <Container style={{ backgroundColor: '#fbf1ea', marginTop: "20px" }}>
            <Grid container spacing={0} sx={{ minHeight: '500px' }}>
              <Grid item xs={12} sm={10} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Grid className='firstlogin'>
                  <b>Welcome !</b>
                  <p>Login to your iParhai Account</p>
                  <b>Email</b>
                  <ThemeProvider theme={customTheme}>
                    <TextField
                      name='email'
                      label="Enter Your Email"
                      variant="outlined"
                      margin="normal"
                      style={buttonlogin}
                      InputLabelProps={{ style: textFieldStyle }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </ThemeProvider>
                  <b>Password</b>
                  <TextField
                    label="Enter Your Password"
                    variant="outlined"
                    type={showPassword ? 'text' : 'password'}
                    margin="normal"
                    style={buttonlogin}
                    className='buttonlogin'
                    InputLabelProps={{ style: textFieldStyle }}
                    sx={{}}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                  <a className='hover:cursor-pointer' onClick={() => setForgetPasswordModal(!forgetPasswordModal)} >Forgot Password?</a>
                  <ReCAPTCHA
                        // sitekey="6LeTDKApAAAAAJhkdpEOte3a67_7gxcx5C60IJG8" //local\
                        sitekey="6LerUAErAAAAAMQaYtyV3Fm7iz4vPUTcsVa_YQK-" //for live
                        onChange={(value) => setRecaptcha(value)}
                      />
                  <Button variant="contained" color="primary" style={buttonlogin1} fullWidth sx={{
                    backgroundColor: "#0A033C", '&:hover': {
                      backgroundColor: "#0A033C", // Maintain the background color on hover
                    },
                  }} onClick={handleSubmit}>
                    Login
                  </Button>
                  {message === 'login Success' && (
                    <div className="success-message">
                      Login successful! Welcome back.
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={2} className='loginsecgrid'>
                <Paper sx={{ backgroundColor: '#FF6652', minHeight: '90%', padding: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <img src={Ilogo} style={{ width: "150%" }} alt="Ilogo" />
                </Paper>
              </Grid>
            </Grid>
          </Container>
        )}
      </form>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={otpmodal}
        // onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={otpmodal}>
          <Box
            sx={{
              position: "absolute",
              borderRadius:'10px',
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 3
            }}
          >
            <Typography
              style={{
                marginBottom: "10px",
                textAlign: "left",
                lineHeight: "1.25",
                fontWeight: "bold",
              }}
            >
              Verify your Email first to login, OTP has been sent to your
              email. If you didn't receive please check Spam in your mail.
              <br />
              <div style={{ position: "absolute", top: "5px", right: "5px" }}>
                {Math.floor(remainingTime / 60)}:
                {remainingTime % 60 < 10 ? "0" : ""}
                {remainingTime % 60}
              </div>
            </Typography>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
              className='my-4 rounded-md'
                id="otp"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Enter Your OTP"
                value={OTP}
                onChange={(e) => { setOTP(e.target.value) }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
            </Paper>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#0A033C", '&:hover': {
                  backgroundColor: "#0A033C", // Maintain the background color on hover
                },
                width:'100%'
              }}
              color="primary"
              onClick={handleVerifyOTP}
              disabled={verifyotploading}
            // id="verify"
            >
              {verifyotploading ? "" : "Verify OTP"}
              {verifyotploading && (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  style={{ marginLeft: "8px" }}
                />
              )}
            </Button>
           <div className="flex gap-4 my-2">
           <Button
              onClick={() => setotpModal(!otpmodal)}
              disabled={verifyotploading || otperror[1] === "success"}
              variant="contained"
              color="error"
              type="submit"
            >
              {/* <FontAwesomeIcon icon={faBackward} /> */}
              Cancel
            </Button>
            <Button
              sx={{ marginLeft: "10px", height: "40px", width: "120px" }}
              onClick={handleResendOtp}
              disabled={remainingTime > 0}
              variant="contained"
              color="primary"
              type="submit"
            >
              {/* <FontAwesomeIcon icon={faBackward} /> */}
              {loadingResendOTP ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  style={{ marginLeft: "8px" }}
                />
              ) : (
                "Resend OTP"
              )}
            </Button>
           </div>
          </Box>
        </Fade>
      </Modal>



      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={forgetPasswordModal}
        // onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={forgetPasswordModal}>
          <Box
            sx={{
              position: "absolute",
              borderRadius:'10px',
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              bgcolor: "#ff6652",
              boxShadow: 24,
              p: 2
            }}
          >

            <h2 className='text-2xl font-semibold text-center'>Forget Password Link</h2>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputBase
                id="otp"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Enter Your Email"
                value={forgetEmail}
                onChange={(e) => { setForgetEmail(e.target.value) }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSubmit(e);
                  }
                }}
              />
            </Paper>

            <div className="flex justify-center gap-10 my-4">
              <Button
                onClick={sendFPLink}
                disabled={verifyotploading || otperror[1] === "success"}
                variant="contained" color="primary" sx={{
                  backgroundColor: "#0A033C", '&:hover': {
                    backgroundColor: "#0A033C", // Maintain the background color on hover
                  }
                }}
                type="submit"
              >
                {emailSendLoading ? <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  style={{ marginLeft: "8px" }}
                />
                  : 'Send'
                }
              </Button>
              <Button
                onClick={() => setForgetPasswordModal(!forgetPasswordModal)}
                disabled={verifyotploading || otperror[1] === "success"}
                variant="contained" color="primary"
                sx={{
                  backgroundColor: "#0A033C", '&:hover': {
                    backgroundColor: "#0A033C", // Maintain the background color on hover
                  }
                }}
              >
                {/* <FontAwesomeIcon icon={faBackward} /> */}
                Cancel
              </Button>
            </div>

          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default Login;
